<template>
  <div class="MessageList">
    <van-nav-bar
      title="消息列表"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="main_list van-hairline--bottom"
          v-for="(item, index) in list"
          :key="index"
          @click="details(item.id, index)"
        >
          <div class="list_left">
            <van-image
              round
              lazy-load
              width="40px"
              height="40px"
              src="https://img.midbest.cn/uploads/image/order/kongjia/2020/11/04/90e91b0ce90a2b986f55be787ad8deba.png"
            />
          </div>
          <div class="list_right">
            <van-cell-group :border="false">
              <van-cell :border="false">
                <template #title>
                  <div class="van-ellipsis">
                    <van-tag type="success" plain v-if="item.status==1">已读</van-tag>
                    <van-tag type="danger" plain v-if="item.status==0">未读</van-tag>
                    {{item.title}}
                  </div>
                </template>
                <template #default>
                  <div>
                    {{item.createtime_str}}
                  </div>
                </template>
              </van-cell>
              <van-cell :border="false">
                <template #title>
                  <div class="van-ellipsis list_content" v-html="item.content">
                    {{item.content}}
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "MessageList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      list: [],
      currSize: 20,
      currOffset: 0,
      loading: false,
      finished: false
    }
  },
  activated() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "消息列表");
  },
  methods: {
    onLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/message/get_list",
          _that.$qs.stringify({
            size: _that.currSize,
            offset: _that.currOffset
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          if (res.data.code == 100000) {
            if (_that.currOffset == 0) {
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            if (res.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
              _that.finished = false;
            } else {
              _that.currOffset += res.data.data.length;
              _that.finished = true;
            }
          }else{
            _that.finished = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    details(id, title){ 
      // 此ID 是发件人id，不是消息id
      title = "系统消息";
      this.$router.push({ name: "MessageDetails", query: {id: id, title: title, shareid: this.shareid, merchid: this.merchid } });
    }
  },
}
</script>
<style lang="less">
.MessageList {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    background-color: #fff;
    padding-bottom: 60px;
    .main_list {
      display: flex;
      padding: 16px 10px;
      align-items:center;
      .list_left {
        width: 40px;
        padding-right: 10px;
        vertical-align: middle;
      }
      .list_right {
        flex: 1;
        overflow: hidden;
        .van-cell {
          padding: 0;
          .van-cell__title {
            flex: 1;
            text-align: left;
            overflow: hidden;
            align-items:flex-start;
            .list_content {
              height: 24px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .list_content p {
              padding: 0;
              margin: 0;
            }
            .list_content p img {
              height: 24px;
              vertical-align: middle;
            }
          }
        }
      }
      
    }
  }
}
</style>